import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store';

Vue.use(Router, VueAxios, axios, store);
const ACCESS_TOKEN_NAME = 'mcdm-token';
const USER_STORAGE_NAME = 'mcdm-user';

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

function setAccessTokenInStorage(token) {
  if (localStorage.getItem(ACCESS_TOKEN_NAME)) {
    localStorage.setItem(ACCESS_TOKEN_NAME, token);
  } else if (sessionStorage.getItem(ACCESS_TOKEN_NAME)) {
    sessionStorage.setItem(ACCESS_TOKEN_NAME, token);
  }
};

function getAccessTokenFromStorage() {
  if (localStorage.getItem(ACCESS_TOKEN_NAME)) {
    return localStorage.getItem(ACCESS_TOKEN_NAME);
  } else if (sessionStorage.getItem(ACCESS_TOKEN_NAME)) {
    return sessionStorage.getItem(ACCESS_TOKEN_NAME);
  }
  return null;
}

function cleanStorage() {
  localStorage.removeItem(USER_STORAGE_NAME);
  localStorage.removeItem(ACCESS_TOKEN_NAME);
  sessionStorage.removeItem(ACCESS_TOKEN_NAME);
}

function isAccessTokenExpired(accessToken) {
  return parseJwt(accessToken).exp < Date.now() / 1000;
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Home',
          path: '',
          component: () => import('@/views/dashboard/Home'),
          meta: {
            title: 'Accueil',
          },
        },
        // Pages
        {
          name: 'Profil',
          path: 'profil',
          component: () => import('@/views/dashboard/pages/Profil'),
          meta: {
            title: 'Profil utilisateur',
          },
        },
        /*
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        */
        {
          name: 'Agenda',
          path: 'agenda',
          component: () => import('@/views/dashboard/pages/agenda/Agenda'),
          meta: {
            title: 'Agenda',
          },
        },
        {
          name: 'CreerFormation',
          path: 'formations/creer',
          component: () => import('@/views/dashboard/pages/formations/CreerFormation'),
          meta: {
            title: 'Nouvelle session de formation',
          },
        },
        {
          name: 'ModifierFormation',
          path: 'formations/:id/modifier',
          component: () => import('@/views/dashboard/pages/formations/CreerFormation'),
          meta: {
            title: 'Modifier la session de formation',
          },
        },
        {
          name: 'FormationDetail',
          path: 'formations/:id',
          component: () => import('@/views/dashboard/pages/formations/FormationDetail'),
          meta: {
            title: 'Détail de la formation',
          },
        },
        {
          name: 'FormationsAnnee',
          path: 'formations/annee/:year',
          component: () => import('@/views/dashboard/pages/formations/Formations'),
          meta: {
            title: 'Formations',
          },
        },
        {
          name: 'Formations',
          path: 'formations',
          redirect: 'formations/annee/' + new Date().getFullYear(),
        },
        {
          name: 'Catalogue',
          path: 'cours',
          component: () => import('@/views/dashboard/pages/cours/Catalogue'),
          meta: {
            title: 'Catalogue',
          },
        },
        {
          name: 'CreerCours',
          path: 'cours/creer',
          component: () => import('@/views/dashboard/pages/cours/CreerCours'),
          meta: {
            title: 'Créer un nouveau cours',
          },
        },
        {
          name: 'ModifierCours',
          path: 'cours/:id/modifier',
          component: () => import('@/views/dashboard/pages/cours/CreerCours'),
          meta: {
            title: 'Modifier le cours',
          },
        },
        {
          name: 'CoursDetail',
          path: 'cours/:id',
          component: () => import('@/views/dashboard/pages/cours/CoursDetail'),
          meta: {
            title: 'Detail du cours',
          },
        },
        {
          name: 'Formateurs',
          path: 'formateurs',
          component: () => import('@/views/dashboard/pages/formateurs/Formateurs'),
          meta: {
            title: 'Formateurs',
          },
        },
        {
          name: 'CreerFormateur',
          path: 'formateurs/creer',
          component: () => import('@/views/dashboard/pages/formateurs/CreerFormateur'),
          meta: {
            title: 'Ajouter un nouveau formateur',
          },
        },
        {
          name: 'FormateurDetail',
          path: 'formateurs/:id',
          component: () => import('@/views/dashboard/pages/formateurs/FormateurDetail'),
          meta: {
            title: 'Détail du formateur',
          },
        },
        {
          name: 'ModifierFormateur',
          path: 'formateurs/:id/modifier',
          component: () => import('@/views/dashboard/pages/formateurs/CreerFormateur'),
          meta: {
            title: 'Mettre à jour le formateur',
          },
        },
        {
          name: 'SousTraitants',
          path: 'sous-traitants',
          component: () => import('@/views/dashboard/pages/sous-traitants/SousTraitants'),
          meta: {
            title: 'Sous-traitants',
          },
        },
        {
          name: 'CreerSousTraitant',
          path: 'sous-traitants/creer',
          component: () => import('@/views/dashboard/pages/sous-traitants/CreerSousTraitant'),
          meta: {
            title: 'Ajouter un sous-traitant',
          },
        },
        {
          name: 'ModifierSousTraitant',
          path: 'sous-traitants/:id/modifier',
          component: () => import('@/views/dashboard/pages/sous-traitants/CreerSousTraitant'),
          meta: {
            title: 'Mettre à jour le sous-traitant',
          },
        },
        {
          name: 'SousTraitantDetail',
          path: 'sous-traitants/:id',
          component: () => import('@/views/dashboard/pages/sous-traitants/SousTraitantDetail'),
          meta: {
            title: 'Détails du sous-traitant',
          },
        },
        {
          name: 'CreerFacture',
          path: 'factures/creer',
          component: () => import('@/views/dashboard/pages/factures/CreerFacture'),
          meta: {
            title: 'Créer une nouvelle facture',
          },
        },
        {
          name: 'ImporterFacture',
          path: 'factures/importer',
          component: () => import('@/views/dashboard/pages/factures/ImporterFacture'),
          meta: {
            title: 'Importer une facture existante',
          },
        },
        {
          path: 'factures/annee/:year',
          component: () => import('@/views/dashboard/pages/factures/Factures'),
          meta: {
            title: 'Factures',
          },
        },
        {
          name: 'Factures',
          path: 'factures',
          redirect: 'factures/annee/' + new Date().getFullYear(),
        },
        {
          name: 'FactureDetail',
          path: 'factures/:id',
          component: () => import('@/views/dashboard/pages/factures/FactureDetail'),
          meta: {
            title: 'Détail de la facture',
          },
        },
        {
          name: 'ModifierFacture',
          path: 'factures/:id/modifier',
          component: () => import('@/views/dashboard/pages/factures/CreerFacture'),
          meta: {
            title: 'Modifier la facture',
          },
        },
        {
          name: 'CreerDevis',
          path: 'devis/creer',
          component: () => import('@/views/dashboard/pages/devis/CreerDevis'),
          meta: {
            title: 'Créer un nouveau devis',
          },
        },
        {
          path: 'devis/annee/:year',
          component: () => import('@/views/dashboard/pages/devis/Devis'),
          meta: {
            title: 'Devis',
          },
        },
        {
          name: 'Devis',
          path: 'devis',
          redirect: 'devis/annee/' + new Date().getFullYear(),
        },
        {
          name: 'DevisDetail',
          path: 'devis/:id',
          component: () => import('@/views/dashboard/pages/devis/DevisDetail'),
          meta: {
            title: 'Détail du devis',
          },
        },
        {
          name: 'ModifierDevis',
          path: 'devis/:id/modifier',
          component: () => import('@/views/dashboard/pages/devis/CreerDevis'),
          meta: {
            title: 'Modifier le devis',
          },
        },
        {
          name: 'Clients',
          path: 'clients',
          component: () => import('@/views/dashboard/pages/clients/Clients'),
          meta: {
            title: 'Clients',
          },
        },
        {
          name: 'CreerClient',
          path: 'clients/creer',
          component: () => import('@/views/dashboard/pages/clients/CreerClient'),
          meta: {
            title: 'Ajouter un nouveau client',
          },
        },
        {
          name: 'ClientDetail',
          path: 'clients/:id',
          component: () => import('@/views/dashboard/pages/clients/ClientDetail'),
          meta: {
            title: 'Détails du client',
          },
        },
        {
          name: 'ModifierClient',
          path: 'clients/:id/modifier',
          component: () => import('@/views/dashboard/pages/clients/CreerClient'),
          meta: {
            title: 'Mettre à jour le client',
          },
        },
        {
          name: 'Financeurs',
          path: 'financeurs',
          component: () => import('@/views/dashboard/pages/financeurs/Financeurs'),
          meta: {
            title: 'Financeurs',
          },
        },
        {
          name: 'CreerFinanceur',
          path: 'financeurs/creer',
          component: () => import('@/views/dashboard/pages/financeurs/CreerFinanceur'),
          meta: {
            title: 'Ajouter un nouveau financeur',
          },
        },
        {
          name: 'FinanceurDetail',
          path: 'financeurs/:id',
          component: () => import('@/views/dashboard/pages/financeurs/FinanceurDetail'),
          meta: {
            title: 'Détails du financeur',
          },
        },
        {
          name: 'ModifierFinanceur',
          path: 'financeurs/:id/modifier',
          component: () => import('@/views/dashboard/pages/financeurs/CreerFinanceur'),
          meta: {
            title: 'Mettre à jour le financeur',
          },
        },
        /*
        {
          name: 'Reviews',
          path: 'reviews',
          component: () => import('@/views/dashboard/pages/reviews/Reviews'),
          meta: {
            title: 'Reviews',
          },
        },
        {
          name: 'CreateReview',
          path: 'reviews/create',
          component: () => import('@/views/dashboard/pages/reviews/CreateReview'),
          meta: {
            title: 'CreateReview',
          },
        },
        {
          name: 'ReviewDetail',
          path: 'reviews/:id',
          component: () => import('@/views/dashboard/pages/reviews/ReviewDetail'),
          meta: {
            title: 'ReviewDetail',
          },
        },
        */
        {
          name: 'Configuration',
          path: 'configuration',
          component: () => import('@/views/dashboard/pages/configuration/Configuration'),
          meta: {
            title: 'Configuration',
          },
        },
      ],
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/login/Login'),
      meta: {
        title: 'Login',
      },
    },
    {
      name: 'ForgotPassword',
      path: '/forgot-password',
      component: () => import('@/views/login/ForgotPassword'),
      meta: {
        title: 'ForgotPassword',
      },
    },
    {
      name: 'ResetPassword',
      path: '/reset-password',
      component: () => import('@/views/login/ResetPassword'),
      meta: {
        title: 'ResetPassword',
      },
    },
    {
      name: 'CreateUser',
      path: '/create-user',
      component: () => import('@/views/login/CreateUser'),
      meta: {
        title: 'CreateUser',
      },
    },
    {
      path: '*',
      component: () => import('@/views/dashboard/erreurs/404'),
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
})

let isRefreshing = false;
let refreshSubscribers = [];

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  // Authentication
  let accessToken = getAccessTokenFromStorage();

  // Pages ne nécessitant pas d'authentification
  const publicPages = ['/login', '/forgot-password', '/reset-password', '/create-user']

  if (!publicPages.includes(to.path) && !accessToken) {
    next({ name: 'Login', params: { path: to.path } });
  } else if (publicPages.includes(to.path) && accessToken) {
    next({ name: 'Home' });
  } else {
    if (!publicPages.includes(to.path) && accessToken) {
      // Vérifier si l'access token a expiré
      if (accessToken && isAccessTokenExpired(accessToken)) {
        // Supprimer le header Authorization pour le renouveler
        delete Vue.axios.defaults.headers.common.Authorization;

        // Obtenir un nouveau access token à partir du cookie stocké
        await axios.post('/auth/refresh', null, { withCredentials: true })
          .then((res) => {
            accessToken = res.data.accessToken;
            setAccessTokenInStorage(accessToken);
          })
          .catch(e => {
            // Si erreur lors de l'obtention d'un nouveau token (refresh token invalide ou expiré)
            // supprimer le cookie contenant le refresh token et vider le local/session storage
            // Puis rediriger vers la page d'accueil
            cleanStorage();
            delete Vue.axios.defaults.headers.common.Authorization;
            axios.delete('/auth/refresh', { withCredentials: true });
            next({ name: 'Login', params: { path: to.path, snack: { text: 'La session a expiré, veuillez vous reconnecter', type: 'warn' } } });
          });
      }

      Vue.axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken;

      // Intercepteur Axios
      Vue.axios.interceptors.request.use(async (config) => {
        // Authentication
        let accessToken = getAccessTokenFromStorage();

        // Vérifier si l'access token a expiré
        if (Vue.axios.defaults.headers.common.Authorization && accessToken && isAccessTokenExpired(accessToken)) {
          // Supprimer le header Authorization pour le renouveler
          delete Vue.axios.defaults.headers.common.Authorization;

          if (!isRefreshing) {
            // Si aucune requête de renouvellement en cours, lancez-en une
            isRefreshing = true;

            // Obtenir un nouveau access token
            await axios.post('/auth/refresh', null, { withCredentials: true })
              .then((res) => {
                accessToken = res.data.accessToken;
                setAccessTokenInStorage(accessToken);

                // Réinitialiser le header Authorization avec le nouveau token
                config.headers.common.Authorization = `Bearer ${accessToken}`;
              })
              .catch(e => {
                // Si erreur lors de l'obtention d'un nouveau token (refresh token potentiellement invalide)
                // supprimer le cookie contenant le refresh token
                cleanStorage();
                delete Vue.axios.defaults.headers.common.Authorization;
                axios.delete('/auth/refresh', { withCredentials: true });
                next({ name: 'Login', params: { path: to.path, snack: { text: 'La session a expiré, veuillez vous reconnecter', type: 'warn' } } });
              })
              .finally(() => {
                isRefreshing = false;
                // Une fois que le token est renouvelé, rejouez toutes les requêtes en attente
                refreshSubscribers.forEach((cb) => cb(accessToken));
                refreshSubscribers = [];
              });
          } else {
            // Si une requête de renouvellement est déjà en cours, attendez qu'elle se termine
            // et ajoutez cette requête à la liste des requêtes en attente
            await new Promise((resolve) => {
              refreshSubscribers.push((accessToken) => {
                config.headers.common.Authorization = `Bearer ${accessToken}`;
                resolve();
              });
            });
          }
        }
        return config;
      }, (error) => {
        return Promise.reject(error);
      });

      Vue.axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          cleanStorage();
        };
        return Promise.reject(error);
      });
    }

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title + ' - TrainyFlow';

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

    next();
  }
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
      window.location.reload()
  }
}); // onError (cache problem)

export default router
