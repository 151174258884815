// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/base';
import './plugins/chartist';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import mavonEditor from 'mavon-editor';
import './assets/css/global.css';
import 'mavon-editor/dist/css/index.css';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import VueAuthImage from 'vue-auth-image';

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.use(mavonEditor);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});
Vue.use(VueAuthImage);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
